import "../styles/Terms.css";

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms of Use Agreement for <strong>LastPage</strong></h1>
      <p className="terms-date">Last updated: 02/22/2025</p>
      <p>
        Welcome to <strong>LastPage</strong>. This Terms of Use Agreement (“Agreement”) governs the use of
        <strong> LastPage</strong>, which allows users to save and share links, post and interact with content, and participate in the community.
      </p>
      <p>
        By using <strong>LastPage</strong>, you agree to comply with these terms. If you do not agree, do not use the application.
      </p>
      
      <h2 className="terms-subtitle">1. Code of Conduct</h2>
      <p>To maintain a safe and respectful environment, interactions between users must comply with the following rules:</p>
      <ul className="terms-list">
        <li><strong>No offensive or inappropriate content is allowed:</strong> You may not post content that is violent, threatening, defamatory, obscene, pornographic, discriminatory, or illegal.</li>
        <li><strong>Harassment and abuse will not be tolerated:</strong> You may not harass, threaten, or intimidate other users.</li>
        <li><strong>Respect copyright and privacy rights:</strong> Do not share content without the author's permission or disclose others' private information.</li>
        <li>Do not use <strong>LastPage</strong> for spam or unauthorized advertising.</li>
        <li>You must be at least 18 years old to use <strong>LastPage</strong>.</li>
      </ul>
      <p>If you violate these rules, your account may be suspended or deleted without prior notice.</p>
      
      <h2 className="terms-subtitle">2. Moderation and Reports</h2>
      <p>
        We reserve the right to remove content or restrict access to any user who violates these terms.
      </p>
      <p>
        If you come across offensive content or abusive behavior, you can report it within the app. We will review reports and take appropriate action.
      </p>
      
      <h2 className="terms-subtitle">3. Privacy and Security</h2>
      <p>
        All information shared in <strong>LastPage</strong> as “Hotlinks” can be viewed by other users. All information shared in <strong>LastPage</strong> as “Collections” can be viewed by other users based on each collection's privacy settings.
      </p>
      
      <h2 className="terms-subtitle">4. Changes to the Terms</h2>
      <p>
        We may update these terms at any time. If we make significant changes, we will notify you within the app.
      </p>
      
      <h2 className="terms-subtitle">5. Contact</h2>
      <p>
        If you have questions or need to report a violation, you can contact us through social media:
      </p>
      <p><a href="https://www.instagram.com/lastpageapp/" className="terms-link">Instagram: lastpageapp</a></p>
      
      <h2 className="terms-subtitle">Glossary</h2>
      <p><strong>Hotlinks:</strong> Hotlinks within <strong>LastPage</strong> are all links you share with the community.</p>
      <p><strong>Collections:</strong> Collections are groups of links that users voluntarily save within the application.</p>
    </div>
  );
};

export default TermsOfUse;
