import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import CollectionPage from './pages/CollectionPage';
import TermsOfUse_ES from './pages/Terms-ES';
import TermsOfUse_EN from './pages/Terms-EN';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/share/collections" element={<CollectionPage />} />
          <Route path="/es/terms" element={<TermsOfUse_ES />} /> 
          <Route path="/en/terms" element={<TermsOfUse_EN />} /> 
          <Route path="/terms" element={<TermsOfUse_EN />} /> 
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;